import React, { memo, useContext, useEffect, useRef, useState } from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { MobileContext } from "../../Context/mobile"

const GridImages = ({ data }) => {

    const { mobile } = useContext(MobileContext)
    const [numberOfImages, setNumber] = useState(8)


    useEffect(() => {
        if (!mobile) {
            setNumber(12)
        } else {
            setNumber(6)
        }
    }, [mobile])


    let imagesList = [...data.edges, ...data.edges].slice(0, numberOfImages).map((product, index) => {
        return [0, 1]
    })

    const [images, setImage] = useState(imagesList)
    const [nRun, setRun] = useState(true)

    useEffect(() => {
        if (nRun) {
            setRun(false)
            imagesList.forEach((image, index) => {
                image.forEach((image2, index2) => {
                    if (index % 2 === 0) {
                        setInterval(() => {
                            document.querySelector(`div[data-index3="${index}"][data-index1="1"][data-index2="${index2}"]`)?.classList.remove("z-4i")
                            setTimeout(() => {
                                document.querySelector(`div[data-index3="${index}"][data-index1="1"][data-index2="${index2}"]`)?.classList.add("z-4i")
                            }, 2000)
                        }, 4000);
                    } else {



                        setInterval(() => {
                            setTimeout(() => {


                                document.querySelector(`div[data-index3="${index}"][data-index1="1"][data-index2="${index2}"]`)?.classList.remove("z-4i")
                                setTimeout(() => {
                                    document.querySelector(`div[data-index3="${index}"][data-index1="1"][data-index2="${index2}"]`)?.classList.add("z-4i")
                                }, 1999)
                            }, 2000)

                        }, 4000);

                    }

                })
            })
        }


    }, [])


    const GridColumns = memo(({ product, index3 }) => {
        return (product.node.imageshome.slice(0, 2).map((image, index1) => {

            return (<div key={index1} className="m-t-6 m-b-6 re  flex-grow-1 ">
                <div className="p-t-50 re ">


                    <div key={index1} data-index1={1} data-index2={index1} data-index3={index3} className={" ab h-100p w-100p t-0 z-4i z-2"} name={index1} id={index1} >
                        <GatsbyImage loading="eager" alt="" image={image.gatsbyImageData} className="h-100p w-100p ab t-0" imgClassName="ab" />
                    </div>
                    <div key={index1 + 1} data-index1={2} data-index2={index1} data-index3={index3} className={" ab h-100p w-100p t-0  z-3"} name={index1 + 1} id={index1 + 1} >
                        <GatsbyImage loading="eager" alt="" image={product.node.imageshome[(index1 === 4 ? 0 : index1 + 1)].gatsbyImageData} className="h-100p w-100p ab t-0" imgClassName="ab" />
                    </div>



                </div>
            </div>)
        }))
    })

    return (
        <div className="flex flex-r p-t-9 p-b-9"> {[...data.edges, ...data.edges].slice(0, 8).map((product, index3) => {

            return <div key={index3} className="m-r-3 m-l-3 flex-grow-1"> <GridColumns product={product} index3={index3} /> </div>
        })} </div>
    )
}

export default GridImages