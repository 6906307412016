import React, { useContext } from 'react';
import SplitSectionHalf from '../../Layouts/SplitSectionHalf';
import { GatsbyImage } from 'gatsby-plugin-image'

import ButtonHoverNone from "../../ResuableComponents/buttonNotHover/index"
import ChangePage from '../../Context/ChangePage';
import LineGen from '../../ResuableComponents/LineGen/LineGen';
import { MobileContext } from '../../Context/mobile';
import { scale, scaleDown } from "../../Functions/fadeTransition"
const CatSectionsImages = ({ allDatoCmsProductcat, location, ...props }) => {

    const { mobile } = useContext(MobileContext)

    const sections = allDatoCmsProductcat.edges.map((el, index) => {
        const { name, image, description, slug, id } = el.node

        return (
            <SplitSectionHalf
                key={index}
                className="h-100vh p-b-0 h-100vh-br-u"
                innerClassName="p-b-0"
                left={
                    <>
                        {(index !== 0 && mobile) ? <div className="p-b-15"> <LineGen numberOfLines={10} /> </div> : null}
                        <ChangePage to={"/collections"} onMouseOver={(e) => { scale(e.target) }} onMouseLeave={(e) => { scaleDown(e.target) }} className="w-100p h-100p flex">
                            <div className="h-100p w-100p p-r-15 br p-e-n m-h-100p h-100vh h-100vh-br-u" id={id} >
                                <GatsbyImage alt="" image={image.gatsbyImageData} className="h-100p  w-100p" imgClassName="enlarge" />
                            </div>
                        </ChangePage>
                    </>
                }
                right={
                    <div className="h-100p w-100p flex al-e al-e-br h-100vh-br-u" >
                        <div className="flex flex-c h-100p m-w-200-br m-t-15-br">
                            <div className='flex-grow-1 m-b-15-br-s'>
                                <h3>
                                    COLLECTION
                                </h3>
                            </div>
                            <div className='flex-grow-1 flex flex-c'>
                                <p className="m-b-30 m-b-30-br">
                                    {description}
                                </p>
                                <ChangePage to={slug} >
                                    <ButtonHoverNone className="" name='See All' />
                                </ChangePage>
                            </div>
                        </div>
                    </div>
                }
            />

        )

    })
    return (<div {...props}>
        {sections}
    </div>)

}

export default CatSectionsImages