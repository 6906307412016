import React, { useContext } from "react"

import Flickity from 'react-flickity-component'
import Product from "../Product";
import { addFilter, removeFilter } from "../../Functions/fadeTransition"
import { MobileContext } from "../../Context/mobile";
import { useMemo } from "react";


const Slider = ({ productsSlider, location }) => {
    const { mobile } = useContext(MobileContext)

    const flickityOptions = {
        "freeScroll": true, "contain": true, "prevNextButtons": false, "pageDots": false,
        cellAlign: 'left', 'wrapAround': (!mobile ? true : false), "autoPlay": (!mobile ? 3000 : false), 'friction': 0.0001, 'selectedAttraction': 0.03,
        friction: 0.8,

        on: {
            staticClick: function (event, pointer, cellElement, cellIndex) {

                cellElement.querySelector('button').click()
            },
            ready: function () {
                const Slider = document.querySelector('.flickity-slider')
                Slider.style.transform = 'translateX(0px)'
            },

        }
    }

    const SwiperEl = () => {
        return (
            <>
                {(!mobile ? <Flickity
                    className={'carousel'}
                    elementType={'div'}
                    options={flickityOptions}
                    disableImagesLoaded={false}
                    reloadOnUpdate
                >
                    {productsSlider.edges.slice(0, 15).map((item, index) => (
                        <ProductItem location={location} mobile={mobile} item={item} key={index} />
                    ))}
                </Flickity> :
                    <div className="w-100p o-h" >
                        <div  style={{ overflow: 'scroll' }}>
                            <div className="flex p-r-15 " style={{ width: `${productsSlider.edges.length * 100}vw` }}>
                                {productsSlider.edges.slice(0, 15).map((item, index) => (
                                    <ProductMobile location={location} mobile={mobile} item={item} key={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            <div className="m-l-57 p-b-15">
                <SwiperEl />
            </div>
        </>
    )
}


function ProductItem({ item, mobile, location }) {
    const handleClickAdd = (item) => {
        if (!mobile) {
            addFilter(item.node.id)
        }
    }

    const landscapeImage = useMemo(() => {
        const { width, height } = item.node.heroimage
        return (width > height)
    }, [])

    const classNames = useMemo(() => {

        return ('p-c   ' + (!landscapeImage ? 'carousel-cell' : 'carousel-cell-large'))
    }, [])

    return (
        <div className={classNames} >
            <div role="button" tabIndex="0" onMouseEnter={() => { handleClickAdd(item) }} className="wrapper z-4  h-100p re " id={item.node.id} onMouseLeave={() => { removeFilter() }}>
                <div className={(!mobile ? 'p-e-n' : ' ' + " h-100p")}>
                    <Product ratio={(landscapeImage ? 'p-t-62p' : 'p-t-114p')} productInfo={item.node} location={location} className="" classWrapper="o-h" />
                </div>
            </div>
        </div>

    )
}

function ProductMobile({ item, mobile, location }) {

    const landscapeImage = useMemo(() => {
        const { width, height } = item.node.heroimage
        return (width > height)
    }, [])

    const classNames = useMemo(() => {

        return ('p-c   ' + (!landscapeImage ? 'carousel-cell' : 'carousel-cell-large'))
    }, [])

    return (
        <div className={classNames} >
            <Product ratio={(landscapeImage ? 'p-t-62p' : 'p-t-114p')} productInfo={item.node} location={location} className="" classWrapper="o-h" />
        </div>
    )
}
export default Slider