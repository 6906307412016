import React from 'react'


const SplitSectionTopBot = ({ top, bot }) => {
    return (
        <div className="flex flex-c ">

            {top}


            {bot}


        </div>
    )
}

export default SplitSectionTopBot