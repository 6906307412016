import React ,{createContext, useState} from 'react'

export const CurrentItem = createContext()

const ItemContext = ({children}) =>{
    const [currentId,setId] = useState(null)
    return (
        <CurrentItem.Provider value={{currentId,setId}}>
            {children}
        </CurrentItem.Provider>
    )
}

export default ItemContext