import React from 'react'
const No = ({ mobile = false }) => {
    if (!mobile) {
        return (
            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.3 23.8H31.22V4.9H30.05C29.42 8.44 27.38 9.91 25.19 11.29L22.64 12.88L23.24 13.9L25.7 12.4C26.99 11.59 27.77 10.84 28.28 9.97V23.8H21.2V25H38.3V23.8Z" fill="#3F3D3D" />
                <path d="M0 6.30322C3.00879 6.30322 5.99522 6.30322 9 6.30322C9 12.6282 9 18.9401 9 25.251C8.79139 25.3084 0.361055 25.3253 0 25.2667C0 18.9582 0 12.6456 0 6.30322ZM1.36742 10.3651C1.35195 10.3697 1.3359 10.3747 1.32043 10.3792C1.32043 14.9576 1.32043 19.536 1.32043 24.129C3.32056 24.129 5.30177 24.129 7.3317 24.129C5.33673 19.5253 3.35208 14.9452 1.36742 10.3651ZM2.95778 7.4505C4.52407 11.1756 6.0766 14.8726 7.67785 18.5515C7.67785 14.8551 7.67785 11.1582 7.67785 7.4505C6.10698 7.4505 4.55616 7.4505 2.95778 7.4505Z" fill="#3F3D3D" />
                <path d="M11 11.2917C11.0107 10.4151 11.0954 9.55157 11.3809 8.71761C11.5603 8.19346 11.8109 7.70855 12.1856 7.29639C12.7538 6.67106 13.4632 6.34816 14.3017 6.30837C14.8152 6.28392 15.3157 6.34361 15.7914 6.55168C16.5047 6.86378 17.0041 7.39872 17.3562 8.08488C17.6401 8.63745 17.8071 9.22811 17.8917 9.84093C18.0582 11.0518 18.0481 12.2587 17.7529 13.452C17.6085 14.0358 17.3805 14.5849 17.016 15.067C16.3128 15.9982 15.3626 16.3768 14.2244 16.2915C12.9948 16.1994 12.1495 15.5332 11.6106 14.4434C11.2663 13.747 11.1146 12.9966 11.0463 12.228C11.0181 11.917 11.0147 11.6038 11 11.2917ZM16.0747 11.3463C16.0482 10.7698 16.0284 10.1928 15.9923 9.61752C15.9771 9.37307 15.9325 9.12976 15.8919 8.88702C15.8298 8.52148 15.7344 8.16561 15.5403 7.84555C15.3248 7.49025 15.015 7.29583 14.5934 7.27252C14.0286 7.24182 13.6409 7.49252 13.3943 7.99279C13.2211 8.34468 13.128 8.72159 13.0789 9.10645C12.8938 10.568 12.8943 12.0319 13.0783 13.494C13.1291 13.8976 13.2284 14.2933 13.4174 14.6588C13.6465 15.1017 14.0077 15.3405 14.5043 15.3399C15.011 15.3393 15.397 15.1057 15.6013 14.6367C15.7282 14.345 15.8259 14.0335 15.8868 13.7214C16.0403 12.9386 16.0612 12.1433 16.0747 11.3463Z" fill="#3F3D3D" />
                <path d="M18 24.3292C18 24.6473 18 24.9511 18 25.2541C17.786 25.3033 11.6394 25.3241 11.0124 25.2758C11.0084 25.1272 11.0032 24.9739 11.0009 24.8205C10.9992 24.6634 11.0004 24.5067 11.0004 24.3543C11.228 24.3031 17.3672 24.2828 18 24.3292Z" fill="#3F3D3D" />
            </svg>)

    } else {
        return (
            < svg width="33" height="17" viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path d="M16.875 11.13C16.155 11.13 15.615 11.64 15.615 12.39C15.615 13.14 16.155 13.65 16.875 13.65C17.595 13.65 18.135 13.14 18.135 12.39C18.135 11.64 17.58 11.13 16.875 11.13ZM32.1059 12.9H28.5659V3.45H27.9809C27.6659 5.22 26.6459 5.955 25.5509 6.645L24.2759 7.44L24.5759 7.95L25.8059 7.2C26.4509 6.795 26.8409 6.42 27.0959 5.985V12.9H23.5559V13.5H32.1059V12.9Z" fill="#3F3D3D" />
                <path d="M0 3.875C1.69244 3.875 3.37231 3.875 5.0625 3.875C5.0625 7.43277 5.0625 10.9833 5.0625 14.5331C4.94516 14.5654 0.203093 14.5749 0 14.542C0 10.9934 0 7.44259 0 3.875ZM0.769175 6.15983C0.760471 6.16237 0.751445 6.16522 0.742741 6.16775C0.742741 8.7431 0.742741 11.3184 0.742741 13.902C1.86781 13.902 2.98225 13.902 4.12408 13.902C3.00191 11.3124 1.88554 8.73613 0.769175 6.15983ZM1.66375 4.52034C2.54479 6.61572 3.41809 8.69526 4.31879 10.7647C4.31879 8.68544 4.31879 6.6059 4.31879 4.52034C3.43518 4.52034 2.56284 4.52034 1.66375 4.52034Z" fill="#3F3D3D" />
                <path d="M6.1875 6.68102C6.19353 6.18793 6.24114 5.7022 6.40176 5.23309C6.50269 4.93826 6.64363 4.66549 6.85439 4.43366C7.17403 4.08191 7.57302 3.90028 8.0447 3.87789C8.33355 3.86414 8.6151 3.89772 8.88268 4.01476C9.2839 4.19031 9.56481 4.49122 9.76288 4.87718C9.92254 5.188 10.0165 5.52025 10.0641 5.86496C10.1577 6.54608 10.152 7.22496 9.98602 7.89616C9.90476 8.22457 9.77653 8.53347 9.57148 8.80463C9.17598 9.32842 8.64145 9.54139 8.00122 9.49342C7.30957 9.44162 6.83408 9.06685 6.53094 8.45384C6.33732 8.06212 6.25194 7.64002 6.21353 7.20769C6.19766 7.03277 6.19575 6.85658 6.1875 6.68102ZM9.04203 6.71172C9.02711 6.38747 9.016 6.0629 8.99568 5.73929C8.98711 5.60179 8.96204 5.46493 8.93918 5.32838C8.90427 5.12277 8.85062 4.92259 8.74143 4.74256C8.62018 4.5427 8.44592 4.43334 8.20881 4.42023C7.89107 4.40296 7.67301 4.54398 7.5343 4.82538C7.43685 5.02332 7.38448 5.23533 7.35686 5.45182C7.25275 6.27395 7.25307 7.09737 7.35654 7.91982C7.38511 8.14686 7.44098 8.36942 7.54731 8.57504C7.67618 8.82414 7.87933 8.95845 8.15866 8.95813C8.4437 8.95781 8.66081 8.82638 8.77571 8.56257C8.84713 8.39852 8.90204 8.22329 8.93633 8.04773C9.02266 7.6074 9.03441 7.16004 9.04203 6.71172Z" fill="#3F3D3D" />
                <path d="M10.125 14.0146C10.125 14.1935 10.125 14.3645 10.125 14.5349C10.0046 14.5625 6.54716 14.5743 6.19445 14.5471C6.1922 14.4635 6.18931 14.3772 6.18803 14.291C6.18707 14.2026 6.18771 14.1144 6.18771 14.0287C6.31576 14 9.76908 13.9885 10.125 14.0146Z" fill="#3F3D3D" />
            </svg >
        )
    }
}

export default No