import React, { useContext, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import SplitSectionTopBot from "../components/Layouts/SplitSectionToBot/SplitSectionTopBot"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LineGen from "../components/ResuableComponents/LineGen/LineGen"
import BodyWrapper from "../components/Layouts/BodyWrapper/index.js"
import SplitSectionHalf from "../components/Layouts/SplitSectionHalf/index.js"
import { graphql } from "gatsby"
import CatSectionsImages from "../components/MediatorComponents/CatSectionsImages/index"
import GridImages from "../components/MediatorComponents/GridImages/index.js"
import Slider from "../components/MediatorComponents/Slider/index.js"
import Footer from "../components/MediatorComponents/Footer/index.js"
import ButtonHoverNone from "../components/ResuableComponents/buttonNotHover/index.js"
import ItemContext from "../components/Context/ItemsFilter"
import { MobileContext } from "../components/Context/mobile"
import Lines from "../components/MediatorComponents/Loading/Lines"
import ChangePage from "../components/Context/ChangePage"
import No from "../components/Styles/Images/No"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"

const IndexPage = ({ data, location }) => {
  const { mobile } = useContext(MobileContext)

  const scale = data => {
    document.querySelector(`[${data}]`).querySelector(".enlarge").style.transition = ` transform 1s ease`
    document.querySelector(`[${data}]`).querySelector(".enlarge").style.transform = ` scale(1.05)`
  }

  const scaleDown = data => {
    document.querySelector(`[${data}]`).querySelector(".enlarge").style.transform = ` scale(1.00)`
  }

  return (
    <Layout location={location}>
      <Seo title='Home' />
      <div className='h-100vh re'>
        <div id='lines' className='p-1 fade h-100vh  h-tran w-100vw z-50  flex al-e flex-c   b-0 o-h ab t-0'>
          <Lines color={"background-white"} text={false} />
        </div>
        <GatsbyImage image={data.home.heroImage.gatsbyImageData} className='h-100vh w-100vw ab z-49' alt='' />
      </div>
      <BodyWrapper>
        <div className='p-t-15'>
          <LineGen numberOfLines={!mobile ? 16 : 10} />
        </div>
      </BodyWrapper>
      <SplitSectionTopBot
        top={
          <div className='flex flex-r p-t-15 '>
            <div className='flex flex-grow-1 al-s p-l-57px p-l-57-u'>
              {" "}
              <h2>COLLECTION</h2>
            </div>
            <div className='flex flex-grow-1 al-e p-r-15'>{mobile ? <No mobile={true} /> : <No />}</div>
          </div>
        }
        bot={
          <div className=' m-t-340 r'>
            <ItemContext>
              <div>
                <Slider productsSlider={data.products} location={location} />
              </div>
            </ItemContext>
          </div>
        }
      />
      <BodyWrapper>
        <div>
          <div className='flex flex-c'>
            <LineGen numberOfLines={!mobile ? 16 : 10} />
            <SplitSectionHalf
              className='m-h-75vh flex flex-c flex-grow-1'
              innerClassName='h-100p flex flex-c flex-grow-1'
              left={
                <div className='flex flex-r'>
                  <div className='flex-grow-1 flex al-s al-s-v dn br'>
                    <h3>THE STUDIO</h3>
                  </div>
                  <div className='flex-grow-1 m-w-300 p-r-20  indent m-w-232-br'>
                    <ReactMarkdown>{data.home.intro}</ReactMarkdown>

                    <ChangePage
                      to={"/studio/"}
                      onMouseOver={() => {
                        scale("data-studio")
                      }}
                      onMouseLeave={() => {
                        scaleDown("data-studio")
                      }}
                    >
                      <ButtonHoverNone className='m-t-15' name='Read More' />
                    </ChangePage>
                  </div>
                </div>
              }
              right={
                <div className='flex  al-e al-e-br w-100p-br'>
                  <div className='flex-sb-br flex-r-br flex-br w-100p-br' data-studio>
                    <GatsbyImage image={data?.home?.imageStudio?.gatsbyImageData} className='m-w-200 m-l-160-br' imgClassName='enlarge' alt='' />
                  </div>
                </div>
              }
            />
            <LineGen numberOfLines={8} />
          </div>
          <CatSectionsImages allDatoCmsProductcat={data.allDatoCmsProductcat} className='p-b-15' />
          <LineGen numberOfLines={8} />
          <GridImages data={data.productsGrid} />
          <Footer data={data} location={location} />
        </div>
      </BodyWrapper>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    home: datoCmsHomepage {
      heroImage {
        gatsbyImageData
      }
      intro
      imageStudio {
        gatsbyImageData
      }
    }
    allDatoCmsProductcat {
      edges {
        node {
          id
          slug
          name
          image {
            gatsbyImageData
          }
          description
        }
      }
    }
    products: allDatoCmsProduct(sort: { fields: position }, filter: { slug: { ne: "owl-sweatshirt" } }) {
      edges {
        node {
          cat {
            slug
          }
          heroimage {
            gatsbyImageData(aspectRatio: 0.9, placeholder: DOMINANT_COLOR)
            url
            width
            height
          }
          name
          year
          launchDate
          totalQuantity
          currentQuantity
          slug
          id
          imageshome {
            gatsbyImageData(aspectRatio: 0.9, placeholder: DOMINANT_COLOR)
            url
            width
            height
          }
        }
      }
    }
    productsGrid: allDatoCmsProduct(filter: { showInSliderOnHome: { eq: true } }) {
      edges {
        node {
          cat {
            slug
          }
          heroimage {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
            url
          }
          name
          year
          totalQuantity
          currentQuantity
          slug
          id
          imageshome {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
            url
          }
        }
      }
    }
  }
`
