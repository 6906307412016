import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import ChangePage from '../../Context/ChangePage'
import Arrow from '../../ResuableComponents/Arrow'

const Product = ({ productInfo, className, location, classWrapper, ratio = 'p-t-114p' }) => {

    const { name, year, slug, heroimage, id, launchDate } = productInfo

    return (
        <div className={"p-c " + className}>
            <ChangePage className={"flex flex-c flex-sb re  w-100p clickable"} to={`/product/${slug}`} key={id} id={id}   >
                <div className="flex flex-c w-100p h-4vh">
                    <div className="flex flex-c ">
                        <h5 className=" p-b-25-br d">{year}</h5>
                        <h5 className='uppercase'>{name}</h5>
                    </div>
                </div>
                <div className="n-e w-100p">
                    <div className="flex flex-r flex-sb p-t-25 m-b-5">
                        <Arrow />
                        <div className="flex flex-r"> <h5 className="m-l-15 uppercase">{launchDate}</h5></div>
                    </div>
                    <div key={id} className={"  re  w-99-5p "} id={id}  >
                        <div className={"w-100p  ov-h  re " + ratio + ' ' + classWrapper}>
                            <GatsbyImage loading='eager' objectFit='cover' data-scroll-speed={0} objectPosition='top center' className={" z-4 l-0 w-100p ab h-100p t-0 tran-sa image p-1 tran-opacity-filter "} image={heroimage.gatsbyImageData} />
                            <div data-scroll-speed={0} data-scroll className="ab  h-100p w-100p t-0  l-0   greyFilter z-3 tran-filter tran-opacity-filter  p-1" id="overlay">
                                <GatsbyImage  loading='eager' objectFit='cover' objectPosition='top center' className={" w-100p ab h-100p t-0 tran-sa l-0 ab w-100p h-100p mix-blend "} image={heroimage.gatsbyImageData} />
                            </div>
                        </div>
                    </div>
                </div>
            </ChangePage>
        </div>
    )
}

export default Product